import { Directive, HostListener, Input } from '@angular/core';

import { InsightsEvent, InsightsEventProperties } from '@mp/shared/app-insights/domain';

import { InsightsEventsTrackingService } from './insights-events-tracking.service';

@Directive({
  selector: '[mpAppInsightsEvent]',
  standalone: true,
})
export class AppInsightsEventDirective {
  @Input('mpAppInsightsEvent')
  eventName!: InsightsEvent;

  @Input()
  eventAdditionalProperties?: InsightsEventProperties;

  constructor(private insightsEventsTrackingService: InsightsEventsTrackingService) {}

  @HostListener('click')
  trackedFeatureClick(): void {
    if (!this.eventName) {
      throw new Error('Event name has to be provided in order to track feature insights!');
    }

    this.insightsEventsTrackingService.trackEvent(this.eventName, this.eventAdditionalProperties);
  }
}
